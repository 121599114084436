<template>
  <div>
    <!-- select 2 demo -->

    <b-modal id="modal-login" centered ok-only hide-footer="true" title="Package">
      <b-form>
        <b-form-group label="Title" invalid-feedback="Title is required.">
          <b-form-input id="name-input" ref="name" placeholder="Enter Title here." required @focusout="CheckName()"
            v-model="stripObj.title" />
        </b-form-group>
        <b-row>
          <b-col md="6">
            <b-form-group label="Amount" invalid-feedback="amount is required.">
              <b-form-input type="number" id="name-input" ref="amount" placeholder="Enter amount here." required
                @focusout="CheckAmount()" v-model="myObj.amount" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Yearly Amount" invalid-feedback="Yearly amount is required.">
              <b-form-input type="number" id="name-input" ref="yearly" placeholder="Enter yearly amount" required
                @focusout="CheckYearly()" v-model="myObj.yearlyAmount" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Recurrance" invalid-feedback="recurrance is required." ref="recurrance">
          <v-select ref="recurrance" placeholder="Select recurrance." v-model="stripObj.recurrance"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="recurranceOptions" label="text"
            :reduce="(option) => option.value" :clearable="false" @input="CheckRecurrance()" />
        </b-form-group>
        <b-form-group label="Details" invalid-feedback="Details is required.">
          <b-form-textarea id="name-input" ref="details" placeholder="Enter details here." required
            @focusout="CheckDetails()" v-model="myObj.details"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Intended for" invalid-feedback="type is required." ref="type">
          <v-select ref="type" placeholder="Select type." v-model="myObj.packageType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="intendedOptions" label="text"
            :reduce="(option) => option.value" :clearable="false" @input="CheckType()" />
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button class="float-right ml-5" variant="primary" :disabled="request" @click="AddFlavour()">
          <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <!-- <b-form-group label="Search Business Type" invalid-feedback="Search field">
      <b-form-input
        id="name-input"
        ref="name"
        placeholder="Enter business name to search"
        required
        @input="SearchData()"
        v-model="searchQuery"
      />
    </b-form-group> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add Flavour -->
          <b-col class="mt-2" md="10" sm="8">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Package</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- <b-col md="2" cols="4">
            <label for="">Filter</label>
            <v-select
              placeholder="Select status."
              v-model="filterStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              @input="FilterTypes()"
              :clearable="false"
            />
          </b-col> -->
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(icon)="data">
            <b-img :src="data.value" style="width: 100px; height: 100px; object-fit: cover" rounded alt="Rounded image" />
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Edit" @click="OpenAddModal(data.item.id)">
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete" size="16"
                @click="DeleteFlavour(data.item.id)">
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalOrders" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";
import { text } from "body-parser";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    BFormTextarea,
    //BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus: "",
      fileProfile: "",
      logoloading: false,
      errors: {
        status: false,
      },
      recurranceOptions: [
        { text: "1 month", value: 1 },
        { text: "2 month", value: 2 },
        { text: "3 month", value: 3 },
        { text: "4 month", value: 4 },
        { text: "5 month", value: 5 },
        { text: "6 month", value: 6 },
        { text: "7 month", value: 7 },
        { text: "8 month", value: 8 },
        { text: "9 month", value: 9 },
        { text: "10 month", value: 10 },
        { text: "11 month", value: 11 },
        { text: "12 month", value: 12 },
      ],
      intendedOptions: [
        { text: "Talent", value: "talent" },
        { text: "Talent Scouts", value: "talent scouts" },
      ],
      index: null,
      fields: [
        "#",
        { label: "Title", key: "title" },
        { label: "Details", key: "details" },
        // { label: "planID", key: "planID" },
        { label: "Status", key: "status" },
        "actions",
      ],
      filterStatus: "",
      items: [],
      request: false,
      myObj: {
        id: 0,
        title: "",
        amount: "",
        details: "",
        status: "active",
        planID: "",
        recurrance: "",
        packageType: "",
        yearlyAmount: "",
        planIDYerly: "",
      },
      stripObj: {
        recurrance: 0,
        title: "",
        amount: 0,
        key: "Tg9bl3pY7aF3CZpxZFTH24CO00Btqn7WLH",
      },
      searchQuery: "",
      prevAmount: null,
      prevYearly: null,
    };
  },
  methods: {
    SearchData() {
      if (this.searchQuery.length > 0) {
        var data = this.items.filter(
          (data) =>
            JSON.stringify(data)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
        );
        this.items = data;
      } else {
        this.LoadData();
      }
    },
    FilterTypes() {
      var axios = require("axios");

      if (this.filterStatus == "All") {
        var config = {
          method: "get",
          url: "https://waqta.appick.io/api/business",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            this.items = response.data.data;
            // response.data.data.forEach(e => {
            //   e.marketplaceShare = e.marketplaceShare * 100;
            //   this.items.push(e);
            // });

            // console.log(this.items);
            //console.log(this.businesses);
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        var config = {
          method: "get",
          url:
            "https://waqta.appick.io/api/business/LoadData?status=" +
            this.filterStatus,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            this.items = response.data.data;
            // response.data.data.forEach(e => {
            //   e.marketplaceShare = e.marketplaceShare * 100;
            //   this.items.push(e);
            // });

            // console.log(this.items);
            //console.log(this.businesses);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
      // this.$store.state.userData.userID
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.stripObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRecurrance() {
      console.log("rec", this.stripObj.recurrance);
      var elem = this.$refs["recurrance"];
      if (this.stripObj.recurrance == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckType() {
      console.log("type", this.myObj.packageType);
      var elem = this.$refs["type"];
      if (this.myObj.packageType == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAmount() {
      let check = parseInt(this.myObj.amount);
      // console.log(check);
      var elem = this.$refs["amount"];
      if (isNaN(check) || check < 0) {
        return (elem.state = false);
      } else {
        // this.myObj.amount = check;
        console.log(this.myObj.amount);
        return (elem.state = true);
      }
    },
    CheckYearly() {
      let check = parseInt(this.myObj.yearlyAmount);
      // console.log(check);
      var elem = this.$refs["yearly"];
      if (isNaN(check) || check < 0) {
        return (elem.state = false);
      } else {
        // this.myObj.amount = check;
        console.log(this.myObj.yearlyAmount);
        return (elem.state = true);
      }
    },
    CheckDetails() {
      // console.log(this.myObj.details);
      var elem = this.$refs["details"];
      if (this.myObj.details == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      // console.log(this.myObj.icon);
      var elem = this.$refs["image"];
      if (this.myObj.icon == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStatus() {
      var elem = this.$refs["status"];
      if (this.myObj.status == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    OpenAddModal(id) {
      if (id != 0) {
        var axios = require("axios");
        var config = {
          method: "get",
          url: "https://api.geotalent.co/api/Packages/" + id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            console.log(response);
            this.myObj = response.data.data;
            this.stripObj.title = this.myObj.title;
            this.stripObj.recurrance = parseInt(this.myObj.recurrance);
            if (this.myObj.yearlyAmount == null) this.myObj.yearlyAmount = "0";
            this.prevAmount = this.myObj.amount;
            this.prevYearly = this.myObj.yearlyAmount;
            this.$bvModal.show("modal-login");
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        this.myObj = {
          id: 0,
          title: "",
          amount: "",
          details: "",
          status: "active",
          planID: "",
          recurrance: "",
          packageType: "",
          yearlyAmount: "0",
          planIDYerly: "",
        };
        this.stripObj = {
          recurrance: 0,
          title: "",
          amount: 0,
          key: "Tg9bl3pY7aF3CZpxZFTH24CO00Btqn7WLH",
        };
        this.$bvModal.show("modal-login");
      }
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/Packages/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Package has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      console.log(this.fileProfile);
      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://geoupload.appick.io/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            this.plus = fn1;
            this.myObj.icon = this.plus;
            // console.log(this.plus);
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.icon = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    AddFlavour() {
      console.log(this.stripObj.amount);
      this.CheckName();
      this.CheckAmount();
      this.CheckYearly();
      this.CheckRecurrance();
      this.CheckDetails();
      this.CheckType();
      if (
        this.CheckName() == false ||
        this.CheckAmount() == false ||
        this.CheckRecurrance() == false ||
        this.CheckDetails() == false ||
        this.CheckType() == false ||
        this.CheckYearly() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        this.request = true;
        if (this.myObj.id == 0) {
          this.stripObj.amount = this.myObj.amount;
          // this.stripObj.amount = parseInt(
          //   (this.myObj.amount / 3.67).toFixed(2)
          // );
          console.log(this.stripObj.amount);
          this.myObj.title = this.stripObj.title;
          this.myObj.recurrance = this.stripObj.recurrance.toString();
          console.log("strip", this.stripObj);
          console.log("myObj", this.myObj);
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://geopayment.appick.io/stripeGeoTalent/createPlan",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.stripObj,
          };
          axios(config)
            .then((response) => {
              console.log(response);
              if (response.data.plan) {
                this.myObj.planID = response.data.plan.id;

                // yearly plan
                if (this.myObj.yearlyAmount > 0) {
                  // let amt = parseInt(
                  //   (this.myObj.yearlyAmount / 3.67).toFixed(2)
                  // );
                  let amt = this.myObj.yearlyAmount;
                  let yearObj = {
                    recurrance: 12,
                    title: this.stripObj.title,
                    amount: amt,
                    key: "Tg9bl3pY7aF3CZpxZFTH24CO00Btqn7WLH",
                  };

                  var axios = require("axios");
                  var config = {
                    method: "post",
                    url:
                      "https://geopayment.appick.io/stripeGeoTalent/createPlan",
                    headers: {
                      Authorization:
                        "bearer " + this.$store.state.userData.token,
                    },
                    data: yearObj,
                  };
                  axios(config)
                    .then((response) => {
                      console.log(response);
                      if (response.data.plan) {
                        this.myObj.planIDYerly = response.data.plan.id;

                        this.savePlan();
                      }
                    })
                    .catch(function(error) {
                      console.log(error);
                    });
                } else {
                  this.savePlan();
                }
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
                this.request = false;
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          //Edit
          this.request = true;
          console.log("edit_Obj", this.myObj);
          if (this.prevAmount !== this.myObj.amount) {
            this.stripObj.amount = this.myObj.amount;
            // this.stripObj.amount = parseInt(
            //   (this.myObj.amount / 3.67).toFixed(2)
            // );
            console.log(this.stripObj.amount);
            this.myObj.title = this.stripObj.title;
            this.myObj.recurrance = this.stripObj.recurrance.toString();
            console.log("strip", this.stripObj);
            console.log("myObj", this.myObj);
            var axios = require("axios");
            var config = {
              method: "post",
              url: "https://geopayment.appick.io/stripeGeoTalent/createPlan",
              headers: {
                Authorization: "bearer " + this.$store.state.userData.token,
              },
              data: this.stripObj,
            };
            axios(config)
              .then((response) => {
                console.log(response);
                if (response.data.plan) {
                  this.myObj.planID = response.data.plan.id;
                } else {
                  this.$bvToast.toast("Something went wrong.", {
                    title: "Error!",
                    variant: "danger",
                    toaster: "b-toaster-bottom-center",
                  });
                  this.request = false;
                }
              })
              .catch(function(error) {
                console.log(error);
              });
          }
          // yearly plan
          if (
            this.prevYearly != this.myObj.yearlyAmount &&
            this.myObj.yearlyAmount > 0
          ) {
            // let amt = parseInt((this.myObj.yearlyAmount / 3.67).toFixed(2));
            let amt = this.myObj.yearlyAmount;
            let yearObj = {
              recurrance: 12,
              title: this.stripObj.title,
              amount: amt,
              key: "Tg9bl3pY7aF3CZpxZFTH24CO00Btqn7WLH",
            };

            var axios = require("axios");
            var config = {
              method: "post",
              url: "https://geopayment.appick.io/stripeGeoTalent/createPlan",
              headers: {
                Authorization: "bearer " + this.$store.state.userData.token,
              },
              data: yearObj,
            };
            axios(config)
              .then((response) => {
                console.log(response);
                if (response.data.plan) {
                  this.myObj.planIDYerly = response.data.plan.id;

                  this.editPlan();
                }
              })
              .catch(function(error) {
                console.log(error);
              });
          } else {
            this.editPlan();
          }
        }
      }
    },
    savePlan() {
      console.log(this.myObj);
      var axios = require("axios");
      var config = {
        method: "post",
        url: "https://api.geotalent.co/api/Packages",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
        data: this.myObj,
      };
      axios(config)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            Swal.fire(
              "Success!",
              "Package added successfully!",
              "success"
            ).then((res) => {
              this.$bvModal.hide("modal-login");
              this.request = false;
              this.LoadData();
            });
          } else {
            this.$bvToast.toast("Something went wrong.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-bottom-center",
            });
            this.request = false;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    editPlan() {
      var axios = require("axios");
      // this.$store.state.userData.userID
      var config = {
        method: "put",
        url: "https://api.geotalent.co/api/Packages/" + this.myObj.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
        data: this.myObj,
      };
      axios(config)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            this.$bvModal.hide("modal-login");
            this.request = false;
            this.LoadData();
            this.$bvToast.toast("Package updated.", {
              title: "Success!",
              variant: "success",
              toaster: "b-toaster-top-center",
            });
          } else {
            this.$bvToast.toast("Something went wrong.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-bottom-center",
            });
            this.request = false;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadData() {
      // console.log("userId",this.$store.state.userData)
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Packages",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.items = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
